import React from 'react'
//import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/Layout'
import devicesFr from '../images/devices-fr.jpg'
//import devicesEn from '../images/devices-en.jpg'
import googlePlay from '../images/google-play-badge-fr.png'
import appStore from '../images/app-store-badge-fr.svg'
import '../styles/styles.scss'

const IndexPage = () => {
  //const { t } = useTranslation()
  const title = 'Bergamot - Cahier de recette pour iOS, Android et Ordinateur'
  const description =
    "Sauvegardez vos recettes depuis n'importe quel site internet, consultez les depuis n'importe où !"
  return (
    <Layout title={title} description={description}>
      <main>
        <section className="section">
          <div className="container is-fullhd intro">
            <div className="columns intro is-reversed-touch">
              <div className="column intro__left">
                <div className="intro__title block-l title is-1">
                  Sauvegardez toutes vos recettes, <strong>au même endroit</strong>
                </div>
                <div className="block-l text-center-mobile is-hidden-mobile">
                  <a
                    className="button is-primary is-rounded is-medium button--gradient"
                    href="https://dashboard.bergamot.app">
                    {/* {t('Open Bergamot Web')} */}Ouvrir Bergamot Web
                  </a>
                </div>
                <div className="intro__store_badges block-l text-center-mobile">
                  <a
                    href="https://apps.apple.com/us/app/bergamot-your-recipe-manager/id1537430681#?platform=iphone"
                    target="blank">
                    <img className="intro__appstore" src={appStore} alt="Apple store" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=app.bergamot.android"
                    target="blank">
                    <img className="intro__appstore" src={googlePlay} alt="Google play" />
                  </a>
                </div>
                <div className="block-l text-center-mobile is-hidden-tablet">
                  <a
                    className="button is-primary is-outlined is-rounded is-small"
                    href="https://dashboard.bergamot.app">
                    {/* {t('Open Bergamot Web')} */}Ouvrir Bergamot Web
                  </a>
                </div>
              </div>
              <div className="column">
                <img
                  className="intro__devices"
                  src={devicesFr}
                  alt="Bergamot on computer and iphone"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
export default IndexPage
